import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/episode-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "discord-community"
    }}>{`Discord Community`}</h1>
    <p>{`You might be asking, why join the community?`}</p>
    <p>{`First of, we are a bunch of folks that enjoy helping others grow in tech. We talk about anything related to tech. About books that we are reading, or things that we are working on.`}</p>
    <p>{`We are also kicking off some cool content to get the community involved:`}</p>
    <ul>
      <li parentName="ul">{`Monthly meetups/discussions about a specific topic`}</li>
      <li parentName="ul">{`Study groups`}</li>
      <li parentName="ul">{`Book clubs`}</li>
      <li parentName="ul">{`Hanging out and chat in the voice chat`}</li>
    </ul>
    <p>{`As being part of the community, you will also be the first one to know when and who the next guest(s) are!`}</p>
    <div className="my-6">
    <a className="green-button my-5 p-2 no-underline hover:red" href="https://discord.gg/ZUk4eRh">Join the community</a>
    </div>
    <p className="text-xs">By joining the discord community, you are agreeing to obey the code of conduct.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      